export default `
setlocal enableextensions enabledelayedexpansion

set PATH="C:\\Riot Games\\League of Legends"

if exist "{path}" (
	SET PATH={path})
cls

if exist "%PATH%\\Game" (
	cd /d "!PATH!\\Config"
	for /F "delims=" %%a in ('find "        locale: " LeagueClientSettings.yaml') do set "locale=%%a"
	for /F "tokens=2 delims=: " %%a in ("!locale!") do set "locale=%%a"

	cd /d "!PATH!\\Game"

	if exist "League of Legends.exe" (
		@start "" "League of Legends.exe" "spectator {authority} {key} {gameId} {platform}" "-UseRads" "-Locale=!locale!" "-GameBaseDir=.."
	)
)`
